import styled from "@emotion/styled";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import tw from "twin.macro";
import ArrowLeft from "../images/arrow-left.svg";
import ArrowRight from "../images/arrow-right.svg";
import { useSwipeable } from "react-swipeable";
import { useSetRecoilState } from "recoil";
import { whiteUiState } from "../state/atoms";
import useInView from "react-cool-inview";

const MobileCarousel = ({
    mobileImages,
    selectedImageIdx,
    setSelectedImageIdx,
}) => {
    const isWhiteUi = mobileImages[selectedImageIdx].description.includes(
        "white"
    );

    const setWhiteUi = useSetRecoilState(whiteUiState);
    const { observe } = useInView({
        onEnter: () => setWhiteUi(isWhiteUi),
        onLeave: () => setWhiteUi(false),
    });

    useEffect(() => {
        if (isWhiteUi) {
            setWhiteUi(true);
        } else {
            setWhiteUi(false);
        }
    }, [isWhiteUi]);

    return (
        <StyledCarousel ref={observe}>
            {mobileImages.map((image, i) => {
                const nextImage = () => {
                    if (i < mobileImages.length - 1) setSelectedImageIdx(i + 1);
                    else setSelectedImageIdx(0);
                };
                const previousImage = () => {
                    if (i === 0) setSelectedImageIdx(mobileImages.length - 1);
                    else setSelectedImageIdx(i - 1);
                };
                const isSelected = i === selectedImageIdx;

                return (
                    <MobileImage
                        key={image.resize.src}
                        {...{ isSelected }}
                        src={image.resize.src}
                        next={() => nextImage()}
                        previous={() => previousImage()}
                    />
                );
            })}
        </StyledCarousel>
    );
};

const MobileImage = ({ isSelected, src, next, previous }) => {
    const handlers = useSwipeable({
        onSwipedRight: () => next(),
        onSwipedLeft: () => previous(),
    });
    return (
        <React.Fragment>
            <AnimatePresence>
                {isSelected && (
                    <CarouselImage
                        initial={{ opacity: 0, translateX: -16 }}
                        animate={{ opacity: 1, translateX: 0 }}
                        exit={{ opacity: 0, translateX: 16 }}
                        transition={{
                            ease: "easeInOut",
                            duration: 0.15,
                        }}
                        onClick={() => next()}
                        {...{ src }}
                        {...handlers}
                    />
                )}
            </AnimatePresence>
        </React.Fragment>
    );
};

const DesktopCarousel = ({
    desktopImages,
    selectedImageIdx,
    setSelectedImageIdx,
}) => {
    const duration = 0.25;
    const isWhiteUi = desktopImages[selectedImageIdx].images.some((image) =>
        image.description.includes("white")
    );

    const setWhiteUi = useSetRecoilState(whiteUiState);
    const { observe } = useInView({
        onEnter: () => setWhiteUi(isWhiteUi),
        onLeave: () => setWhiteUi(false),
    });

    useEffect(() => {
        if (isWhiteUi) {
            setWhiteUi(true);
        } else {
            setWhiteUi(false);
        }
    }, [isWhiteUi]);

    return (
        <StyledCarousel ref={observe}>
            {desktopImages.map((image, i) => {
                const previousImage = () => {
                    if (i !== 0) setSelectedImageIdx(i - 1);
                    else setSelectedImageIdx(desktopImages.length - 1);
                };
                const nextImage = () => {
                    if (i < desktopImages.length - 1)
                        setSelectedImageIdx(i + 1);
                    else setSelectedImageIdx(0);
                };

                const isSelected = i === selectedImageIdx;
                const isColumned = image.images.length === 2;

                const Controls = () => {
                    return (
                        <div
                            tw="absolute grid w-full h-full z-10"
                            style={{ gridTemplateColumns: "1fr 1fr" }}
                        >
                            <PreviousButton
                                onClick={() => previousImage()}
                                style={{
                                    cursor: `url(${ArrowLeft}) 30 30, auto`,
                                }}
                            />
                            <NextButton
                                onClick={() => nextImage()}
                                style={{
                                    cursor: `url(${ArrowRight}) 30 30, auto`,
                                }}
                            />
                        </div>
                    );
                };

                if (isColumned)
                    return (
                        <React.Fragment key={image.id}>
                            {isSelected && <Controls />}
                            <AnimatePresence>
                                {isSelected && (
                                    <motion.div
                                        tw="grid w-full h-full absolute"
                                        initial={{
                                            opacity: 0,
                                            translateX: 0,
                                        }}
                                        animate={{
                                            opacity: 1,
                                            translateX: 0,
                                        }}
                                        exit={{
                                            opacity: 0,
                                            translateX: -8,
                                        }}
                                        transition={{
                                            ease: "easeInOut",
                                            duration,
                                        }}
                                        style={{
                                            gridTemplateColumns: "1fr 1fr",
                                        }}
                                    >
                                        <CarouselImage
                                            tw="w-1/2"
                                            initial={{
                                                opacity: 0,
                                            }}
                                            animate={{
                                                opacity: 1,
                                            }}
                                            exit={{
                                                opacity: 0,
                                            }}
                                            transition={{
                                                ease: "easeInOut",
                                                duration,
                                            }}
                                            src={image.images[0].resize.src}
                                            onClick={() => nextImage()}
                                        />
                                        <CarouselImage
                                            tw="left-1/2 w-1/2"
                                            initial={{
                                                opacity: 0,
                                            }}
                                            animate={{
                                                opacity: 1,
                                            }}
                                            exit={{
                                                opacity: 0,
                                            }}
                                            transition={{
                                                ease: "easeInOut",
                                                duration,
                                                delay: duration * 1.5,
                                            }}
                                            src={image.images[1].resize.src}
                                            onClick={() => nextImage()}
                                        />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </React.Fragment>
                    );

                return (
                    <React.Fragment key={image.id}>
                        {isSelected && <Controls />}
                        <AnimatePresence>
                            {isSelected && (
                                <CarouselImage
                                    initial={{ opacity: 0, translateX: 0 }}
                                    animate={{ opacity: 1, translateX: 0 }}
                                    exit={{ opacity: 0, translateX: -8 }}
                                    transition={{
                                        ease: "easeInOut",
                                        duration: duration * 1.5,
                                    }}
                                    src={image.images[0].resize.src}
                                    onClick={() => nextImage()}
                                />
                            )}
                        </AnimatePresence>
                    </React.Fragment>
                );
            })}
        </StyledCarousel>
    );
};

export { MobileCarousel, DesktopCarousel };

const StyledCarousel = styled.div`
    ${tw`w-full h-full inset-0 overflow-hidden bg-transparent`};
`;

const CarouselImage = styled(motion.div)<{ src: string }>`
    ${tw`absolute grid w-full h-full`};
    background-image: ${({ src }) => `url('${src}')`};
    background-position: center;
    background-size: cover;
`;

const PreviousButton = styled.button`
    background-blend-mode: difference;
`;

const NextButton = styled.button``;
